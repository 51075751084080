<template>
    <div>
        <b-card v-if="isLoading">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading, please wait...</strong>
            </div>
        </b-card>
        <b-row>
            <b-col cols="12" md="8" v-if="!isLoading && appData">
                <b-card v-if="appData.automation && (appData.processing_status === 'error' || appData.processing_status === 'completed')">
                    <template #header>
                        <div class="d-flex justify-content-between w-100">
                            <h4 class="cart-title mr-1">App Edit</h4>
                            <app-detail-status :appData="appData"></app-detail-status>
                        </div>
                    </template>
                    <validation-observer ref="appForm" tag="form"
                                         @submit.prevent="validationFormControl().then(formSubmitted)">
                        <b-row class="align-items-center">
                            <b-col md="6">
                                <b-form-group label="Team" label-for="team_id">
                                    <validation-provider #default="{ errors }" name="team_id" rules="required">
                                        <v-select id="team_id" v-if="teamList" v-model="appData.team_id"
                                                  :options="teamList" :reduce="(option) => option.value"
                                                  label="label" placeholder="Select Team" class="w-100">
                                        </v-select>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" class="d-flex align-items-center justify-content-between">
                                <div class="w-100 mr-1">
                                    <b-form-group label-for="link">
                                        <template #label>
                                            <div>Icon <span v-if="appData.newicon" style="font-size:10px">*When updated,
                                                    your old icon will change.</span></div>
                                        </template>
                                        <b-form-file v-model="appData.newicon" ref="file"
                                            @change="handleFileObject($event)"
                                            accept=".png">
                                            <template slot="file-name" slot-scope="{ names }">
                                                <b-badge variant="primary">
                                                    {{ names[0] }}
                                                </b-badge>
                                            </template>
                                        </b-form-file>
                                    </b-form-group>
                                </div>
                                <div>
                                    <b-media vertical-align="center" id="appIcon">
                                        <template #aside>
                                            <div class="position-relative">
                                                <b-badge v-if="!deleteIcon && !appData.newicon && appData.icon"
                                                    @click="deleteOldIcon()" variant="primary"
                                                    v-b-tooltip.hover.top="'Delete Icon'"
                                                    class="position-absolute cursor-pointer" style="z-index:5">
                                                    <feather-icon icon="XIcon" size="12" />
                                                </b-badge>
                                                <b-badge v-if="deleteIcon && !appData.newicon && !appData.icon"
                                                    @click="backGetIcon()" variant="primary"
                                                    v-b-tooltip.hover.top="'Undo Change'"
                                                    class="position-absolute cursor-pointer" style="z-index:5">
                                                    <feather-icon icon="CornerUpLeftIcon" size="12" />
                                                </b-badge>
                                                <b-avatar rounded="sm" size="45"
                                                    :src="reviewIcon?reviewIcon:backend_url+appData.icon"
                                                    :text="avatarText(appData.name)" :variant="`light-secondary`" />
                                            </div>
                                        </template>
                                    </b-media>
                                </div>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="App Name" label-for="name">
                                    <validation-provider #default="{ errors }" name="App Name" maxlength="3"
                                                         rules="required">
                                        <b-form-input :state="errors.length > 0 ? false:null" placeholder="App Name"
                                                      disabled @keydown="nameKeydown($event)" v-model="appData.name"/>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Code" label-for="code">
                                    <validation-provider #default="{ errors }" name="code" rules="required">
                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                      placeholder="3 letter code" :maxlength="3"
                                                      v-model="appData.code"/>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <hr/>
                            </b-col>
                            <b-col md="12">
                                <b-form-group label="Platforms" label-for="Platforms">
                                    <app-collapse>
                                        <app-collapse-item class="app-client" :isVisible="has_ios">
                                            <template #header>
												<span class="lead collapse-title d-flex align-items-center" :style="appData.selected_ios?'pointer-events: none;':''">
													<b-form-checkbox @click="changeBoolVal('ios')"
                                                                     class="app-platform-checkbox"
                                                                     v-model="appData.has_ios"
                                                                     value="true" disabled required>
													</b-form-checkbox>
													<span @click="changeBoolVal('ios')"
                                                          class="app-platform-title">IOS</span>
												</span>
                                            </template>
                                            <b-row>
                                                <b-col md="12">
                                                    <validation-provider #default="{ errors }" name="Appstore Account"
                                                                         :rules="this.appData.has_ios?'required':''">
                                                        <b-form-group label="Appstore Account"
                                                                      label-for="Appstore Account"
                                                                      :state="errors.length > 0 ? false:null">
                                                            <v-select :options="appAccounts.appstoreAccounts"
                                                                      :reduce="(option) => option.id"
                                                                      :disabled="appData.selected_ios?true:false"
                                                                      label="name"
                                                                      placeholder="Select Appstore Account"
                                                                      class="w-100"
                                                                      v-model="appData.appstore_account_id">
                                                            </v-select>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group label="Bundle Identifier"
                                                                  label-for="Bundle Identifier">
                                                        <validation-provider #default="{ errors }"
                                                                             :rules="this.appData.has_ios?'required':''">
                                                            <b-form-input :disabled="appData.selected_ios?true:false"
                                                                          placeholder="Bundle Identifier"
                                                                          v-model="appData.bundle"
                                                                          :state="errors.length > 0 ? false:null"/>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group label="Appstore ID" label-for="Appstore ID">
                                                        <validation-provider #default="{ errors }" name="Appstore ID"
                                                                             :rules="this.appData.has_ios?'required':''">
                                                            <b-form-input :disabled="appData.selected_ios?true:false"
                                                                          placeholder="Appstore ID"
                                                                          v-model="appData.appstore_id"
                                                                          type="number"
                                                                          :state="errors.length > 0 ? false:null"/>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-form-group label="Admob App ID" label-for="Admob App ID">
                                                            <b-form-input placeholder="Admob App ID" type="text" v-model="appData.admob_ios_app_id"/>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>
                                        <app-collapse-item title="Andorid" class="app-client" :isVisible="has_android">
                                            <template #header>
												<span class="lead collapse-title d-flex align-items-center" :style="appData.selected_android?'pointer-events: none;':''">
													<b-form-checkbox @click="changeBoolVal('android')"
                                                                     class="app-platform-checkbox"
                                                                     v-model="appData.has_android"
                                                                     value="true" disabled required>
													</b-form-checkbox>
													<span @click="changeBoolVal('android')"
                                                          class="app-platform-title">Android</span>
												</span>
                                            </template>
                                            <b-row>
                                                <b-col md="6">
                                                    <validation-provider #default="{ errors }"
                                                                         name="Google Play Account"
                                                                         :rules="this.appData.has_android?'required':''">
                                                        <b-form-group label="Google Play Account"
                                                                      label-for="Google Play Account"
                                                                      :state="errors.length > 0 ? false:null">
                                                            <v-select :options="appAccounts.androidAccounts"
                                                                      :reduce="(option) => option.id" label="name"
                                                                      :disabled="appData.selected_android?true:false"
                                                                      placeholder="Select Google Play Account"
                                                                      class="w-100"
                                                                      v-model="appData.android_account_id">
                                                            </v-select>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <b-form-group label="Package Name" label-for="Package Name">
                                                        <validation-provider #default="{ errors }" name="Package Name"
                                                                             :rules="this.appData.has_android?'required':''">
                                                            <b-form-input :state="errors.length > 0 ? false:null"
                                                                          placeholder="Package Name"
                                                                          :disabled="appData.selected_android?true:false"
                                                                          v-model="appData.package_name"/>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-form-group label="Admob App ID" label-for="Admob App ID">
                                                            <b-form-input placeholder="Admob App ID" type="text" v-model="appData.admob_android_app_id"/>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>
                                    </app-collapse>
                                    <span v-if="platformError" class="text-danger font-12">Please select platform</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <hr class="mt-0"/>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Facebook App ID" label-for="Facebook App ID">
                                    <validation-provider #default="{ errors }" name="Facebook App ID" rules="required">
                                        <b-form-input :state="errors.length > 0 ? false:null"
                                                      placeholder="Facebook App ID" type="number"
                                                      v-model="appData.facebook_id"/>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Facebook Client Token" label-for="Facebook Client Token">
                                <b-form-input placeholder="Facebook Client Token" v-model="appData.facebook_client_token"/>
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group label="Snapchat App ID" label-for="Snapchat App ID">
                                    <b-form-input placeholder="Snapchat App ID" v-model="appData.snapchat_id"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group label="Tiktok Advertiser Account" label-for="Tiktok Advertiser Account">
                                    <v-select :options="appAccounts.tiktokAccounts" :reduce="(option) => option.id"
                                              label="name" placeholder="Select Tiktok Advertiser Account" class="w-100"
                                              v-model="appData.tiktok_account_id">
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Elephant Game ID" label-for="Elephant Game ID">
                                <b-form-input placeholder="Elephant Game ID" v-model="appData.elephant_game_id"/>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Elephant Game Secret" label-for="Elephant Game Secret">
                                <b-form-input placeholder="Elephant Game Secret" v-model="appData.elephant_game_secret"/>
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <hr/>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Product Manager" label-for="Product Manager"
                                              v-if="productManagerUsers" id="memberSelect">
                                    <v-select
                                        v-model="appData.productManagers"
                                        :reduce="(option) => option.key"
                                        label="label"
                                        :options="productManagerUsers"
                                        :clearable="false"
                                        placeholder="Select User"
                                        multiple
                                    >
                                        <template #option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                        <template #selected-option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">

                                <b-form-group label="Developer" label-for="Developer" v-if="developerUsers"
                                              id="memberSelect">
                                    <v-select
                                        v-model="appData.developers"
                                        :reduce="(option) => option.key"
                                        label="label"
                                        :options="developerUsers"
                                        :clearable="false"
                                        placeholder="Select User"
                                        multiple
                                    >
                                        <template #option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                        <template #selected-option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="3D Artist" label-for="3D Artist" v-if="threedArtistUsers"
                                              id="memberSelect">
                                    <v-select
                                        v-model="appData.threeDArtists"
                                        :reduce="(option) => option.key"
                                        label="label"
                                        :options="threedArtistUsers"
                                        :clearable="false"
                                        placeholder="Select User"
                                        multiple
                                    >
                                        <template #option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                        <template #selected-option="{ image, label }">
                                            <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                            <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                            <b-form-group label="Release Build Perm. Members" label-for="Release Build Perm. Members" v-if="releaseBuildPermissionMembers"
                                          id="memberSelect">
                                <v-select
                                    v-model="appData.releaseBuildPermissionMembers"
                                    :reduce="(option) => option.key"
                                    label="label"
                                    :options="releaseBuildPermissionMembers"
                                    :clearable="false"
                                    placeholder="Select User"
                                    multiple
                                >
                                    <template #option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                    <template #selected-option="{ image, label }">
                                        <b-avatar size="18" :src="image" :text="avatarText(label)"/>
                                        <span class="ml-50 d-inline-block align-middle"> {{ label }}</span>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                            <b-col md="12">
                                <hr/>
                            </b-col>
                            <b-col cols="12" class="text-right">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"
                                          v-if="!tryApi">
                                    Update
                                </b-button>
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"
                                          v-if="tryApi">
                                    Update and run
                                </b-button>
                            </b-col>
                        </b-row>

                    </validation-observer>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card title="Try to recreate" v-if="!isLoading && appData && tryApi">
                    <b-row v-if="appData.automation && (appData.processing_status === 'error' || appData.processing_status === 'completed')">
                        <b-col md="12" v-if="appData.has_ios">
                            <b-form-checkbox v-if="!appData.adjust_ios_token" v-model="tryApi" value="true"
                                             class="custom-control-primary mr-1 mb-1" disabled>
                                Adjust iOS
                            </b-form-checkbox>
                            <b-form-checkbox v-if="!appData.applovin_ios_inter" v-model="tryApi" value="true"
                                             class="custom-control-primary mr-1 mb-1" disabled>
                                Applovin iOS Inter
                            </b-form-checkbox>
                            <b-form-checkbox v-if="!appData.applovin_ios_reward" v-model="tryApi" value="true"
                                             class="custom-control-primary mb-1" disabled>
                                Applovin iOS Reward
                            </b-form-checkbox>
                            <b-form-checkbox v-if="!appData.applovin_ios_banner" v-model="tryApi" value="true"
                                             class="custom-control-primary mb-1" disabled>
                                Applovin iOS Banner
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12" v-if="appData.has_android">
                            <b-form-checkbox v-if="!appData.adjust_android_token" v-model="tryApi" value="true"
                                             class="custom-control-primary mr-1 mb-1" disabled>
                                Adjust Android
                            </b-form-checkbox>
                            <b-form-checkbox v-if="!appData.applovin_android_inter" v-model="tryApi" value="true"
                                             class="custom-control-primary mr-1 mb-1" disabled>
                                Applovin Android Inter
                            </b-form-checkbox>
                            <b-form-checkbox v-if="!appData.applovin_android_reward" v-model="tryApi" value="true"
                                             class="custom-control-primary mb-1" disabled>
                                Applovin Android Reward
                            </b-form-checkbox>
                            <b-form-checkbox v-if="!appData.applovin_android_banner" v-model="tryApi" value="true"
                                             class="custom-control-primary mb-1" disabled>
                                Applovin Android Banner
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12" v-if="!appData.repo">
                            <b-form-checkbox v-if="!appData.repo" v-model="tryApi" value="true"
                                             class="custom-control-primary mr-1 mb-1" disabled>
                                Bitbucket
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="12" v-if="!appData.asana_id">
                            <b-form-checkbox v-if="!appData.asana_id" v-model="tryApi" value="true"
                                             class="custom-control-primary mr-1 mb-1 disabled" disabled>
                                Asana
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card v-if="!isLoading && appData && appData.logs && appData.logs.length >0" title="App Logs">
                    <div class="text-left my-2">
                        <app-log-and-version-detail :appData="appData"></app-log-and-version-detail>
                    </div>
                </b-card>
            </b-col>
        </b-row>

    </div>
</template>


<script>
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import useAppApi from '@/composables/useAppApi'
import router from '@/router'
import {required} from '@validations'
import {mapState} from 'vuex'
import diff from 'deep-diff'
import AppLogAndVersionDetail from '@/components/AppLogAndVersionDetail.vue'
import AppCollapse from '@/components/AppCollapse.vue'
import AppCollapseItem from '@/components/AppCollapseItem.vue'
import AppDetailStatus from '@/components/AppDetailStatus.vue'
import {avatarText} from '@core/utils/filter'
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BSpinner,
    BAvatar,
    BFormFile,
    BBadge,
    BMedia,
    VBTooltip
} from 'bootstrap-vue'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCard,
        BCol,
        BFormGroup,
        BForm,
        BButton,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
        BFormCheckbox,
        BSpinner,
        AppLogAndVersionDetail,
        AppCollapse,
        AppCollapseItem,
        AppDetailStatus,
        BAvatar,
        BFormFile,
        BBadge,
        BMedia,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            uploadLoad: false,
            required,
            platformError: false,
            selected_ios: false,
            selected_android: false,
            updateAppData: null,
            differences: {},
            tryApi: false,
            backend_url:process.env.VUE_APP_BACKEND_URL,
            oldicon:null,
            oldiconval:true,
            reviewIcon:null,
            deleteIcon:false,
        }
    },
    methods: {
        formSubmitted() {

            this.updateAppData.developers = this.updateAppData.developers.map((item) => item.key ?? item).sort((a, b) => a - b)
            this.updateAppData.productManagers = this.updateAppData.productManagers.map((item) => item.key ?? item).sort((a, b) => a - b)
            this.updateAppData.threeDArtists = this.updateAppData.threeDArtists.map((item) => item.key ?? item).sort((a, b) => a - b)
            this.updateAppData.releaseBuildPermissionMembers = this.updateAppData.releaseBuildPermissionMembers.map((item) => item.key ?? item).sort((a, b) => a - b)
            this.appData.developers = this.ignoreIndex(this.updateAppData.developers, this.appData.developers.map((item) => item.key ?? item).sort((a, b) => a - b))
            this.appData.productManagers = this.ignoreIndex(this.updateAppData.productManagers, this.appData.productManagers.map((item) => item.key ?? item).sort((a, b) => a - b))
            this.appData.threeDArtists = this.ignoreIndex(this.updateAppData.threeDArtists, this.appData.threeDArtists.map((item) => item.key ?? item).sort((a, b) => a - b))
            this.appData.releaseBuildPermissionMembers = this.ignoreIndex(this.updateAppData.releaseBuildPermissionMembers, this.appData.releaseBuildPermissionMembers.map((item) => item.key ?? item).sort((a, b) => a - b))
            this.differences.updated = diff(this.updateAppData, this.appData)

            if (!this.differences.updated && !this.tryApi && this.controlAccounts()) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Info',
                        icon: 'InfoIcon',
                        variant: 'info',
                        text: 'No change has been made.'
                    },
                })
            } else {
                const formData = new FormData()
                formData.append('id',this.appData.id)
                formData.append('icon',this.appData.icon !== null ? this.appData.icon:'delete')
                formData.append('newicon',this.appData.newicon !== null ? this.appData.newicon:'delete')
                formData.append('_method', 'PUT');
                this.$store.dispatch('appStore/updateApp', formData)
                    .then((response) => {
                        this.differences.id = this.appData.id
                        this.differencesControl()
                        this.$store.dispatch('appStore/updateAppQueue', this.differences)
                            .then((response) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Successfully updated!',
                                        icon: 'CheckIcon',
                                        variant: 'success',
                                    },
                                })
                                router.push({name: 'app-detail', params: { app: this.appData.id }})
                            }).catch((errors) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Please check form',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                    text: errors.response ? errors.response.data.message : ''
                                },
                            })
                        })
                    })

            }
        },
        ignoreIndex(arr1, arr2) {
            const newarray = []
            arr1.forEach((obj1, idx) => {
                if (arr2[idx] !== arr1[idx]) {
                    newarray[idx] = arr2.find(o => o === obj1) ?? "Deleted"
                } else {
                    newarray[idx] = obj1;
                }
            })
            const arr = newarray.concat(arr2.filter((item) => newarray.indexOf(item) < 0));
            return arr;
        },
        validationFormControl() {
            return new Promise((resolve, reject) => {
                this.$refs.appForm.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        changeBoolVal(type) {
            if (type === 'ios') {
                this.appData.has_ios = !this.appData.has_ios
            } else {
                this.appData.has_android = !this.appData.has_android
            }
            if (this.appData.has_ios || this.appData.has_android) this.platformError = false
        },
        differencesControl() {
            this.differences.updated = this.differences.updated.filter(item => {
                if (!this.appData.has_ios && (item.path == 'appstore_account_id' || item.path == 'appstore_id' || item.path == 'bundle')) {
                    this.appData[item.path] = null
                    return false
                }
                if (!this.appData.has_android && (item.path == 'android_account_id' || item.path == 'package_name')) {
                    this.appData[item.path] = null
                    return false
                }
                if(item.path == 'icon' || item.path == 'newicon'){
                    return false
                }

                return true
            })
        },
        nameKeydown(e) {
            if (e.key == "-") e.preventDefault()
        },
        controlAccounts() {
            if (this.updateAppData.facebook_id == this.appData.facebook_id && this.updateAppData.snapchat_id == this.appData.snapchat_id && this.updateAppData.tiktok_account_id == this.appData.tiktok_account_id) {
                return true
            }
        },
        handleFileObject(event) {
            if(this.oldiconval){
                this.oldicon = this.backend_url+this.appData.icon
                this.oldiconval = false
            }
            this.appData.newicon = event.target.files[0]
            this.deleteIcon = false
            if(!this.appData.newicon){
                this.reviewIcon = this.oldicon
                this.appData.icon = this.oldicon
            }
            else this.reviewIcon = URL.createObjectURL(this.appData.newicon)
		},
        deleteOldIcon(){
            if(this.oldiconval){
                this.oldicon = this.backend_url+this.appData.icon
                this.oldiconval = false
            }
            this.appData.icon = null
            this.reviewIcon = null
            this.deleteIcon = true
        },
        backGetIcon(){
            this.appData.icon = this.oldicon
            this.deleteIcon = false
            this.reviewIcon = this.oldicon
        }
    },
    setup() {
        const {
            fetchAccounts,
            fetchEditApp,
            appData,
            fetchUserRoleList,
            developerUsers,
            threedArtistUsers,
            productManagerUsers,
            releaseBuildPermissionMembers,
            teamList,
            fetchTeamList,
        } = useAppApi()
        return {
            fetchAccounts,
            fetchEditApp,
            appData,
            fetchUserRoleList,
            developerUsers,
            threedArtistUsers,
            productManagerUsers,
            releaseBuildPermissionMembers,
            teamList,
            fetchTeamList,
            avatarText
        }
    },
    mounted() {
        this.fetchAccounts()
        this.fetchEditApp(router.currentRoute.params.app)
        this.fetchUserRoleList()
        this.fetchTeamList()
    },
    computed: {
        ...mapState('appStore', ['appAccounts', 'isLoading']),
        has_ios() {
            if (this.appData && !this.appData.has_ios) {
                this.appData.appstore_account_id = null
                this.appData.appstore_id = null
                this.appData.bundle = null
            }
            return this.appData && this.appData.has_ios ? true : false
        },
        has_android() {
            if (this.appData && !this.appData.has_android) {
                this.appData.android_account_id = null
                this.appData.package_name = null
            }
            return this.appData && this.appData.has_android ? true : false
        },
    },
    updated() {
        if (this.updateAppData === null && this.appData !== null) {
            this.appData.developers = this.appData.developers.map(item => ({
                "key": item.key,
                "label": item.label,
                "image": item.image
            }))
            this.appData.threeDArtists = this.appData.threeDArtists.map(item => ({
                "key": item.key,
                "label": item.label,
                "image": item.image
            }))
            this.appData.productManagers = this.appData.productManagers.map(item => ({
                "key": item.key,
                "label": item.label,
                "image": item.image
            }))
             this.appData.releaseBuildPermissionMembers = this.appData.releaseBuildPermissionMembers.map(item => ({
                "key": item.key,
                "label": item.label,
                "image": item.image
            }))


            this.updateAppData = {...this.appData}
            if ((this.updateAppData.has_ios && (!this.updateAppData.adjust_ios_token || !this.updateAppData.applovin_ios_reward || !this.updateAppData.applovin_ios_reward || !this.updateAppData.applovin_ios_banner)) ||
                (this.updateAppData.has_android && (!this.updateAppData.adjust_android_token || !this.updateAppData.applovin_android_reward || !this.updateAppData.applovin_android_reward || !this.updateAppData.applovin_android_banner)) || (!this.updateAppData.asana_id || !this.updateAppData.repo)) {
                this.updateAppData.try_api = false
                this.tryApi = true
                this.appData.try_api = true
            }
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.app-client {
    .card-header {
        position: relative;
        padding: 10px 0px !important;
    }

    .app-platform-checkbox {
        z-index: 0px !important;
    }

    .app-platform-title {
        width: 60vw;
        line-height: 21px;
        padding: 10px 0px;
        position: absolute;
        padding-left: 30px;
        z-index: 3;
    }

    .font-12 {
        font-size: 12px
    }
}

#memberSelect {
    .vs__selected {
        background-color: #dadadb !important;
        color: #232323 !important;
    }
}
#appIcon{
    .media-aside{
        margin-right: 0px!important;
    }
}
</style>
