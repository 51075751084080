<template>
    <div id="app-log-details">
        <app-collapse>
            <app-collapse-item v-for="versionType in appData.logs" :key="versionType.version"
                :title="'Version '+versionType.version">
                <template #header>
                    <span :style="versionType.error?'':''">
                        <b-avatar rounded size="24" :variant="!versionType.error?'light-secondary':'light-danger'"
                            class="mr-8px">
                            <feather-icon size="16" :icon="!versionType.error?'CheckIcon':'XIcon'" />
                        </b-avatar>
                        {{dateFormat(versionType.date,'YYYY-MM-DD HH:mm')}} - v{{versionType.version}}
                    </span>
                </template>
                <app-collapse>
                    <app-collapse-item class="pl-1 pr-2">
                        <template #header>
                            <span>
                                Api Logs
                            </span>
                        </template>
                        <app-collapse>
                            <app-collapse-item v-for="(logType,index) in versionType.types" :key="index"
                                :isVisible="logType.error" class="pl-2 pr-2">
                                <template #header>
                                    <span class="text-capitalize" :style="logType.error?'color:red':''">
                                        <b-avatar rounded size="24"
                                            :variant="!logType.error?'light-success':'light-danger'" class="mr-8px">
                                            <feather-icon size="16" :icon="!logType.error?'CheckIcon':'XIcon'" />
                                        </b-avatar>
                                        {{logType.type}}
                                    </span>
                                </template>
                                <b-card-body class="pr-1 pl-2 pb-0">
                                    <div v-for="log in logType.logs" :key="log.id" class="transaction-item mb-1">
                                        <b-media no-body
                                            @click="showModalResponseDetail(log.message+' - '+dateFormat(log.created_at,'YYYY-MM-DD HH:mm'),log.details)"
                                            v-b-modal.result-detail class="align-items-center">
                                            <b-media-aside class="align-items-center">
                                                <b-avatar rounded size="26"
                                                    :variant="!log.error?'light-success':'light-danger'">
                                                    <feather-icon size="12" :icon="!log.error?'CheckIcon':'XIcon'" />
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body>
                                                <h6 class="transaction-title mb-0 d-block">
                                                    {{ log.message }}
                                                </h6>
                                                <small
                                                    :class="!log.error ? '':'text-danger'">{{ dateFormat(log.created_at,'YYYY-MM-DD HH:mm') }}</small>
                                            </b-media-body>
                                        </b-media>
                                    </div>
                                </b-card-body>
                            </app-collapse-item>
                        </app-collapse>
                    </app-collapse-item>
                    <app-collapse-item v-if="versionType.updates.length>0" class="pl-1 pr-2">
                        <template #header>
                            <span>
                                Updates
                            </span>
                        </template>
                        <div>
                            <b-card v-if="versionType.updates" no-body id="update-table">
                                <b-table :items="versionType.updates" responsive class="mb-0"
                                    :fields="['path','old_value','new_value']"
                                    :table-class="'table dataTable no-footer dtr-column'">
                                    <template #cell(path)="data" class="p-0">
                                        <span v-if="data.item.approved">
                                            <b-badge variant="light-success">
                                                <feather-icon icon="CheckIcon" size="16" />
                                            </b-badge> {{ data.item.path }}
                                        </span>
                                        <span v-else>
                                            <b-badge variant="light-danger">
                                                <feather-icon icon="XIcon" size="16" />
                                            </b-badge> {{ data.item.path }}
                                        </span>
                                    </template>
                                    <template #cell(old_value)="data">
                                        {{ data.item.old_value }}
                                    </template>
                                    <template #cell(new_value)="data">
                                        {{ data.item.new_value }}
                                    </template>
                                </b-table>
                            </b-card>
                        </div>
                    </app-collapse-item>
                </app-collapse>
            </app-collapse-item>
        </app-collapse>
        <b-modal id="result-detail" ok-only ok-title="Close" centered :title="this.modalShowTitle">
            <pre>{{this.modalShowDetail | pretty}}</pre>
        </b-modal>
    </div>
</template>
<script>
import AppCollapse from '@/components/AppCollapse.vue'
import AppCollapseItem from '@/components/AppCollapseItem.vue'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import {
    BModal,
    VBModal,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BTabs,
    BTab,
    BCard,
    BTable,
    BBadge
} from 'bootstrap-vue'
export default {
    components: {
        BModal,
        VBModal,
        BAvatar,
        BMedia,
        BMediaAside,
        BMediaBody,
        BCardBody,
        BTabs,
        BTab,
        AppCollapse,
		AppCollapseItem,
        BCard,
        BTable,
        BBadge
    },
    props: {
        appData: {}
    },
    directives: {
		'b-modal': VBModal,
	},
	filters: {
		pretty: function (value) {
			return JSON.stringify(JSON.parse(value), null, 2);
		}
	},
    methods:{
        showModalResponseDetail(title, type) {
			this.modalShowTitle = title
			this.modalShowDetail = type
		},
		dateFormat(date,format) {
			return moment(date).format(format)
		},
    },
    setup() {
		const modalShowTitle = ref(null)
		const modalShowDetail = ref(null)
		return {
			modalShowTitle,
			modalShowDetail
		}
	},
}
</script>
<style lang="scss">
#update-table{
    th:first-child{
        min-width: 180px!important;
    }
    th,td{
        padding-left:8px!important;
        padding-right: 8px!important;
    }
    .badge{
        background: transparent!important;
    }
}
#app-log-details{
    .table-responsive::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }

    .table-responsive::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    }

    .table-responsive::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
    }
}
#result-detail pre::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
#result-detail pre::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}
#result-detail pre::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
#result-detail pre{
	overflow: auto;
	max-height: 500px;
}
#result-detail pre{
	background:#fff;
}
#result-detail .modal-body{
	padding-bottom: 0px;
}
.mr-8px{
    margin-right: 8px;
}
</style>
